.listItemDiv {
    margin: 8px;
    margin-top: 3px;
    margin-bottom: 3px;
    height: 60px;
    width: 95%;
    display: flex;
    flex-direction: row;
}

.bar {
    width: 5px;
    background-color: #3f51b5;
}

.content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-left: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    justify-content: space-evenly;
}

.contentHead {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 2px;
    padding-bottom: 2px;
}

.contentImg {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.headText {
    font-weight: 600;
}

.timeText {
    color: #3f51b5;
}
