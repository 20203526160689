.add-card-dialog {
  display: flex;
  flex-direction: column;

  &-content {
    display: flex;
    padding: 20px;

    &-col {
      display: flex;
      flex-direction: column;
    }

    &-row {
      display: flex;
      flex-direction: row;
    }
  }
}
