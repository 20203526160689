$appLightBlue: #3c8dbc;
$appHrGrey: #e9ecef;
$deleteRed: #D30101;

.master-page {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.mobile {
    .master-page-sidebar {
      display: none;
    }

    .master-page-header-menu-right-item {
      padding: 0px 10px;
    }
  }
  
  &-header {
    display: flex;
    align-items: center;
    height: 60px;
    background-color: $appLightBlue;
    color: white;

    &-logo {
      display: flex;
      align-items: center;
      margin: 0px 40px;
      font-size: 2rem;
      cursor: pointer;

      img {
        max-width: 140px;
      }
    }

    &-menu {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 100%;

      &-left {
        display: flex;
        font-size: 1.2rem;
  
        &-item {
          display: flex;
          align-items: center;
          padding: 0px 20px;
          cursor: pointer;

          &.selected {
            background-color: white;
            color: $appLightBlue;
          }
        }
      }
  
      &-right {
        display: flex;
        height: 100%;

        &-item {
          display: flex;
          align-items: center;
          padding: 0px 20px;
          cursor: pointer;

          &.selected {
            background-color: white;
            color: $appLightBlue;
          }
        }
      }
    }

    &-username-dropdown {
      display: flex;
      flex-direction: column;
      position: fixed;
      right: 0;
      top: 60px;
      width: 150px;
      border: 1px solid lightgray;
      background-color: white;
      color: black;

      &-item {
        padding: 10px;
        cursor: pointer;
        border: 1px solid lightgray;

        &:hover {
          background-color: lightgray;
        }
      }
    }
  }

  &-content {
    display: flex;
    height: calc(100vh - 60px);


    .master-page-main-content-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;

      .master-page-main-content {
        height: calc(100vh - 120px);
        min-height: 200px;
        overflow: auto;
      }
    }
  }

  &-sidebar {
    display: flex;
    flex-direction: column;
    min-width: 250px;
    height: 100%;
    background-color: #333;
    color: white;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 99;

    &-menu {
      display: flex;
      flex-direction: column;

      &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        padding: 0px 15px;
        background-color: #444;
        border-bottom: 1px solid gray;
        cursor: pointer;

        &.child {
          height: 40px;
          font-size: 0.8rem;
          background-color: #888;
          border-bottom: none;
          color: white;

          &.selected {
            background-color: white;
            color: black;
          }
        }

        &.selected {
          background-color: white;
          color: black;

          &:hover {
            background-color: white;
          }
        }
        
        &:hover {
          background-color: #666;
        }
      }
    }
  }

  &-footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 15px 20px;
    border-top: 1px solid lightgray;
    white-space: nowrap;
    flex-wrap: wrap;

    &-item {
      display: flex;
      align-items: center;
      height: 30px;
    }
  }

  .centered-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 2rem;
  }

  .feedback-footer {
    display: flex;
    margin: auto;
    height: 30px;
    align-items: center;

    &-item {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &-separator {
      margin: 0px 10px;
      color: lightgray;
    }
  }
}

.link {
  color: blue;
  cursor: pointer;

  &.orange {
    color: orange;
  }
}

.feedback-dialog {
  display: flex;
  flex-direction: column;

  &-textarea {
    padding: 20px;
    border-bottom: 1px solid lightgray;

    textarea {
      width: -webkit-fill-available;
      width: -moz-available;
      height: 200px;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid lightgray;
    }
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 20px;

    &-message {
      display: flex;
      flex-direction: column;
      margin-right: 20px;
      text-align: right;
    }
  }
}

.linebreak {
  height: 1rem;
}

.no-overflow {
  overflow: unset;
}


// apply these styles to make everything 100vh
// .master-page.logged-in {
//   height: 100vh;
//   overflow-y: auto;
//   .master-page-content {
//     flex-shrink: 1;
//     overflow-y: auto;
//     .master-page-main-content-wrapper {
//       max-height: calc(100vh - 60px);
//       overflow-y: auto;
//       overflow-x: hidden;
//       .master-page-main-content {
//         overflow-x: auto;
//         min-width: fit-content;
//       }
//       .master-page-footer {
//         background-color: #fff;
//       }
//     }
//   }
// }
